const common = {
    header: {
        home: 'Home',
        legacy: 'Legacies',
        memorials: 'Memorials',
        calm: 'Calm',
        soulGuide: 'Soul Guide',
        plans: 'Plans',
        journal: 'Journal',
        about: 'About',
        us: 'Us',
        blog: 'Blog',
        termsAndCondition: 'Terms and conditions',
        languages: {
            spanish: 'Spanish',
            english: 'English'
        },
        create: 'Create',
        login: 'Login',
        signUp: 'Sign Up',
        logout: 'Log out',
        createMemorial: 'Create Memorial',
        seeLegacies: 'See Legacies',
        new: 'New'
    },
    footer: {
        contactUs: 'Contact us',
        aboutUs: 'About us',
        plans: 'Plans',
        siteOne: 'Sitio Uno',
        moreInformation: 'More information',
        betaProgram: 'Beta Program',
        privacyPolicy: 'Privacy policies',
        termsAndConditions: 'Terms and Conditions',
        cookiesPolicy: 'Cookies Policies',
        followUs: 'Follow us',
        copyright1: 'Created by Sitio Uno, Inc.',
        copyright2: 'All rights reserved',
        childSafetyPolicy: 'Child Safety Standards Policy'
    },
    downloadApp: {
        title: 'Start Planning Your Digital Legacy',
        subtitle: 'Download now!',
        seo: {
            title: 'Download now | Be Soul App',
            description:
                'Elevate with Be Soul app. Download now on App Store & Play Store for transformative moments, seamless sharing, and everlasting memories'
        }
    },
    errors: {
        phoneAlreadyUse: 'Phone is already in use.',
        emailAlreadyUse: 'Email is already in use.',
        invalidCredentials: 'Invalid credentials',
        genericError: 'Something went wrong, try again.'
    },
    success: {
        copied: 'Copied',
        fileDownloaded: 'File downloaded successfully'
    },
    chip: {
        required: 'Required',
        todo: 'To do',
        completed: 'Done'
    },
    seo: {
        title: 'Be Soul - Legacy Planning App',
        description:
            'Discover Be Soul, the ultimate legacy planning app. Connect with loved ones, preserve memories, and share stories for generations to come. Start your legacy journey today!'
    },
    error404: {
        title: { 1: '404. ', 2: 'That’s an error.' },
        description: 'Sorry! We didn’t find this page',
        button: 'Go to home'
    },
    error: {
        title: { 1: 'Oops! ', 2: 'Something went wrong.' },
        description: 'Please refresh the page to continue.',
        button: 'Reload page'
    }
};

export default common;
