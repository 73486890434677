const common = {
    header: {
        home: 'Inicio',
        legacy: 'Legados',
        memorials: 'Memoriales',
        calm: 'Calma',
        soulGuide: 'Guía del Alma',
        plans: 'Planes',
        journal: 'Diarios',
        about: 'Acerca de',
        us: 'Nosotros',
        blog: 'Blog',
        termsAndCondition: 'Términos y condiciones',
        languages: {
            spanish: 'Español',
            english: 'Inglés'
        },
        create: 'Crear',
        login: 'Iniciar sesión',
        signUp: 'Registro',
        logout: 'Cerrar sesión',
        createMemorial: 'Crear Memorial',
        seeLegacies: 'Ver Legados',
        new: 'Nuevo'
    },
    footer: {
        contactUs: 'Contáctanos',
        aboutUs: 'Sobre nosotros',
        plans: 'Planes',
        siteOne: 'Sitio Uno',
        moreInformation: 'Más información',
        betaProgram: 'Programa Beta',
        privacyPolicy: 'Políticas de privacidad',
        termsAndConditions: 'Términos y Condiciones',
        cookiesPolicy: 'Políticas de Cookies',
        followUs: 'Síguenos',
        copyright1: 'Creado por Sitio Uno, Inc.',
        copyright2: 'Todos los derechos reservados',
        childSafetyPolicy: 'Política de Seguridad Infantil'
    },
    downloadApp: {
        title: 'Comienza A Construir Tu Legado Digital',
        subtitle: '¡Descarga ahora!',
        seo: {
            title: 'Descarga ahora | Be Soul App',
            description:
                'Elevate con la aplicación Be Soul. Descárgala ahora en App Store y Play Store para vivir momentos transformadores, compartir sin interrupciones y crear recuerdos eternos.'
        }
    },
    errors: {
        phoneAlreadyUse: 'El teléfono ya está en uso.',
        emailAlreadyUse: 'Correo electrónico ya está en uso.',
        invalidCredentials: 'Credenciales no inválidas.',
        genericError: 'Algo salió mal, intenta de nuevo.'
    },
    success: {
        copied: 'Copiado',
        fileDownloaded: 'Archivo descargado'
    },
    chip: {
        required: 'Requerido',
        todo: 'Por completar',
        completed: 'Completado'
    },
    seo: {
        title: 'Be Soul - Planifica tu Legado',
        description:
            'Descubre Be Soul, la aplicación para la planificación de legados. Conéctate con tus seres queridos, preserva recuerdos y comparte historias para las generaciones venideras. ¡Comienza tu viaje de legado hoy mismo!'
    },
    error404: {
        title: { 1: '404. ', 2: 'Algo salió mal.' },
        description: '¡Lo sentimos! No encontramos esta página.',
        button: 'Ir al inicio'
    },
    error: {
        title: { 1: '¡Oops! ', 2: 'Algo salió mal.' },
        description: 'Por favor, recarga la página para continuar.',
        button: 'Recargar página'
    }
};

export default common;
