export const API_BASE_URL = process.env.REACT_APP_BASE;

export const BESOUL_API_KEY = process.env.REACT_APP_BESOUL;

export const BESOUL_EMAIL = process.env.REACT_APP_BESOUL_EMAIL;

export const BESOUL_SUPPORT_PHONE = process.env.REACT_APP_BESOUL_SUPPORT_PHONE;

export const TENANT_ID = process.env.REACT_APP_TENANT_ID || null;

export const CLARITY_ID = process.env.REACT_APP_MICROSOFT_CLARITY_ID;

export const N8N_BOT_URL = process.env.REACT_APP_N8N_BOT_URL;
