// routing
import Routes from 'routes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
import Locales from 'ui-component/Locales';
// import RTLLayout from 'ui-component/RTLLayout';
import ThemeCustomization from 'themes';
import Snackbar from 'ui-component/extended/Snackbar';
import Notistack from 'ui-component/third-party/Notistack';

// auth provider
import { JWTProvider as AuthProvider } from 'contexts/JWTContext';
// import { FirebaseProvider as AuthProvider } from 'contexts/FirebaseContext';
// import { AWSCognitoProvider as AuthProvider } from 'contexts/AWSCognitoContext';
// import { Auth0Provider as AuthProvider } from 'contexts/Auth0Context';

// Chat n8n
import '@n8n/chat/style.css';
import { createChat } from '@n8n/chat';
import { useEffect } from 'react';
import { N8N_BOT_URL } from 'environment';
import { useTranslation } from 'react-i18next';
import './global.css';

// ==============================|| APP ||============================== //

const App = () => {
    const { t } = useTranslation();
    useEffect(() => {
        createChat({
            webhookUrl: N8N_BOT_URL,
            initialMessages: [t('homeV2.chatBot.initialMessages.1'), t('homeV2.chatBot.initialMessages.2')],
            i18n: {
                en: {
                    title: t('homeV2.chatBot.title'),
                    subtitle: t('homeV2.chatBot.subtitle'),
                    footer: '',
                    getStarted: t('homeV2.chatBot.getStarted'),
                    inputPlaceholder: t('homeV2.chatBot.inputPlaceholder'),
                    closeButtonTooltip: ''
                }
            }
        });
    }, []);

    return (
        <ThemeCustomization>
            {/* RTL layout */}
            {/* <RTLLayout> */}
            <Locales>
                <NavigationScroll>
                    <AuthProvider>
                        <Notistack>
                            <Routes />
                            <Snackbar />
                        </Notistack>
                    </AuthProvider>
                </NavigationScroll>
            </Locales>
            {/* </RTLLayout> */}
        </ThemeCustomization>
    );
};

export default App;
