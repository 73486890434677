const homeV2 = {
    seo: {
        title: 'Be Soul: Aplicación de planificación de legados',
        description:
            'Explora la belleza de compartir recuerdos en línea y embarcate en tu viaje de planificación de legados con nuestra aplicación. Crea un legado digital que perdure para siempre',
        carousel: {
            altImage1:
                'Revive tus recuerdos a través de fotos y vídeos y compártelos con tus seres queridos en nuestra aplicación de legado digital',
            altImage2: 'Organiza tus documentos y testamento con nuestra app para una planificación de legado digital efectiva',
            altImage3: 'Guarda la voz de tus ancestros y su sabiduría para la planificación de tu legado digital'
        },
        digitalMemoriesAltImage: 'Memoriales digitales de un querido perro y una señora, celebrando vidas y recuerdos en línea',
        videoCarousel: {
            video1: 'Liam relata la facilidad de preservar recuerdos con nuestra app de legados digitales - ver en YouTube',
            video2: 'Valeria comparte cómo nuestra app le ayudó a ver un legado digital significativo - ver en YouTube',
            video3: 'Testimonio de Paige sobre su experiencia con nuestra app de legados digitales - ver en YouTube'
        }
    },
    chatBot: {
        title: 'Soul Guide',
        subtitle: 'I am here to help or talk with you',
        footer: '',
        getStarted: 'New Conversation',
        inputPlaceholder: 'Hi! I want to know more about Be Soul',
        closeButtonTooltip: '',
        initialMessages: { 1: 'Hi there!', 2: 'I am here to help or talk with you' }
    },
    banner: {
        title1: 'Be Soul',
        title2: 'A legacy of love and memories',
        subtitle1: 'Explore a unique way to celebrate life and leave an everlasting mark.'
    },
    description: {
        title: 'Be Soul, the most comprehensive App to accompany you at the end of life.',
        subtitle:
            'Our tools are designed to support you and your loved ones, providing guidance and comfort through this important process.',
        text1: 'Create your legacy and choose if you want...',
        tag1: 'A. Release it instantly',
        tag2: 'B. Plan when you are going to release it',
        tag3: 'C. Release it Postmortem',
        legacyModal: {
            title: 'Legacies in Be Soul App',
            text: "We created a tool that allows you to preserve your memories and leave a lasting legacy for your loved ones to cherish, even when you're gone. We developed the legacy feature so you can save and share your most cherished memories in a special and meaningful way. You can include photos, videos, audios, or important documents, and decide when they are released: on a specific date, immediately, or even after you're gone. This way, your stories and feelings will continue to accompany those you love most.",
            button1: 'Learn more',
            button2: 'A space for valuable memories',
            button3: 'A voice that will be treasured forever',
            button4: 'Time Capsule'
        },
        memorialModal: {
            title: 'Memorials on Be Soul App',
            text: "On the Be Soul app, memorials offer an intimate and meaningful space to pay tribute to loved ones who have passed away. Through this feature, users can create a personalized tribute, sharing memories, photos, and messages that keep the memory of those who were important in their lives alive. It's a way to keep their legacy present, creating a gathering place where family and friends can connect, share emotions, and remember the special moments they lived together.",
            button1: 'Honor a loved one',
            button2: 'Honor your pet'
        }
    },
    features: {
        title: 'Document your daily life and share it with your loved ones...',
        card1: {
            title: 'Create daily videos',
            text: 'Capture every day with videos that tell your story. Small moments, big memories.'
        },
        card2: {
            title: 'Record your emotions',
            text: 'After uploading your daily video, reflect on how you feel and keep track of your emotions to connect more with yourself.'
        },
        card3: {
            title: 'Create family groups',
            text: 'Connect your family in an intimate and safe space. Share memories, messages and experience closeness, no matter the distance.'
        }
    },
    soulGuide: {
        title: 'SoulGuide',
        new: 'New',
        subtitle: 'Explore your inner self with tools designed to guide you on your personal journey.',
        card1: {
            title: 'Master number',
            text: 'Discover the hidden meanings behind the numbers in your life.'
        },
        card2: {
            title: 'Birth chart',
            text: 'Learn how the stars influence your path and discover aspects of your personality.'
        },
        card3: {
            title: 'Interpreter of dreams',
            text: 'Understand the messages of your dreams and find answers in your subconscious.'
        },
        card4: {
            title: 'Book of life',
            text: 'Write and review the story of your life, from the small to the big moments.'
        },
        card5: {
            title: 'Chat with Soul Guide',
            text: 'Receive guidance and advice to navigate the most difficult times and find peace.'
        },
        card6: {
            title: 'Oracle',
            text: 'Discover personalized weekly predictions, based on your birth chart.'
        },
        card7: {
            title: 'Oracle',
            text: 'Discover personalized weekly predictions, based on your birth chart.'
        },
        card8: {
            title: 'Oracle',
            text: 'Discover personalized weekly predictions, based on your birth chart.'
        },
        learnMore: 'Learn more'
    },
    emotionalSupport: {
        title: 'SoulGuide, emotional support',
        subtitle:
            'Soulguide is an emotional support AI at your fingertips, receive support in the most difficult moments. You are not alone, we are here to listen to you and support you when you need it most.',
        card1: { title: 'Write to SoulGuide', text: 'Chat with SoulGuide for written help and support.' },
        card2: { title: 'Talk to SoulGuide', text: 'Call toll-free and chat to receive help and support.' },
        card3: {
            title: '+1 (888) 998-1563',
            text1: 'Toll-free number in English',
            text2: ' for those who do not have the resources for professional therapy'
        },
        card4: {
            title: ' +1 (850) 7357297',
            text1: 'Toll-free number in Spanish',
            text2: ' para quienes no cuentan con los recursos para una terapia profesional'
        }
    },
    testimonials: {
        title: 'Testimonials',
        subtitle: 'Discover how others have found support and connection with Be Soul'
    },
    downloadApp: {
        title: 'Download Be Soul and begin your journey of connection and reflection'
    },
    FQ: {
        title: 'Preguntas Frecuentes',
        '1': {
            question: '¿Qué es y para quién es Be Soul App?',
            answer: 'Be Soul está dirigida a cualquier persona que deseen dejar un legado duradero y significativo para sus seres queridos, incluso después de su partida. Permite crear y compartir recuerdos personales, mensajes y reflexiones especiales que perdurarán en el tiempo, logrando mantener viva la memoria y el legado de quien lo utiliza.'
        },
        '2': {
            question: '¿Es posible crear un perfil en Be Soul para alguien que ya falleció?',
            answer: 'Sí, es posible crear un perfil en Be Soul para alguien que ya falleció. La aplicación permite honrar y preservar la memoria de seres queridos fallecidos al permitir la creación de perfiles con sus recuerdos, mensajes y reflexiones. Esto brinda a los usuarios la oportunidad de recordar y compartir la vida y el legado de la persona fallecida.'
        },
        '3': {
            question: '¿Qué cosas puedo compartir en Be Soul?',
            answer: 'En Be Soul puedes compartir una variedad de elementos significativos, tales como fotografías, videos, documentos importantes, recuerdos personales, historias familiares y recetas de cocina con las personas que sea de su elección.'
        },
        '4': {
            question: '¿Cómo funciona el proceso de liberación de los recuerdos asignados a mis seres queridos en Be Soul?',
            answer: 'Be Soul funciona como un testamento/Memorial digital, asegurando que todos los recuerdos y contenido sean preservados y desbloqueados después del fallecimiento de la persona o en una fecha específica. De esta manera, se garantiza que las memorias y las historias importantes perduren en el tiempo, permitiendo que los seres queridos puedan acceder a ellos, encontrar consuelo y conexión emocional.'
        },
        '5': {
            question: '¿Quiénes pueden acceder a tus recuerdos?',
            answer: 'Solo las personas a las que les hayas compartido los recuerdos podrán acceder a ellos. En Be Soul, la privacidad y la selección de audiencia son fundamentales. Puedes elegir de manera selectiva a quién deseas compartir tus recuerdos, asegurando que solo las personas designadas puedan acceder a ellos y mantener así la confidencialidad y la intimidad de tus recuerdos personales.'
        },
        '6': {
            question: '¿Cual es el proceso de validación de un fallecimiento en la aplicación?',
            answer: 'Be Soul está dirigida a cualquier persona que deseen dejar un legado duradero y significativo para sus seres queridos, incluso después de su partida. Permite crear y compartir recuerdos personales, mensajes y reflexiones especiales que perdurarán en el tiempo, logrando mantener viva la memoria y el legado de quien lo utiliza.'
        },
        '7': {
            question: '¿Qué otras funciones tiene la app?',
            answer: 'Además de dejar y recibir testamentos a tus seres queridos, en Be Soul también puedes acceder a los perfiles de las personas fallecidas y dejarles insignias con dedicatorias, lo que permitirá que cualquier persona que visite el perfil pueda apreciar lo valioso que esa persona fue para sus seres queridos. Esta función brinda la oportunidad de honrar y recordar a aquellos que han partido, compartiendo testimonios y expresiones de afecto en un espacio dedicado a su memoria.'
        }
    },
    cookies: {
        title: 'Este sitio usas cookies para ofrecerte una mejor experiencia. Lee más sobre las politicas de las cookies',
        allCookies: 'Aceptar TODAS las cookies del sitio',
        analytics: 'Google Analytics',
        button1: 'Configurar',
        button1Alternate: 'Guardar cambios',
        button2: 'Aceptar cookies'
    }
};

export default homeV2;
